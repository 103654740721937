// routes
import { PATH_CONTRACTOR } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_kanban'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_banking'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  company: getIcon('ic_company'),
  location: getIcon('ic_location'),
  task: getIcon('ic_ourtask'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_CONTRACTOR.general.dashboard, icon: ICONS.dashboard },

      { title: 'Our Contractor', 
        path: PATH_CONTRACTOR.ourcontractor.root, 
        icon: ICONS.user,
          children: [
            { title: 'Company Info', path: PATH_CONTRACTOR.ourcontractor.companyinfo },
            { title: 'User Contact Info', path: PATH_CONTRACTOR.ourcontractor.usercontactinfo },
          ], 
      },
      {
        title: 'Our Task',
        path: PATH_CONTRACTOR.ourtask.root,
        icon: ICONS.task,
        children: [
          { title: 'Saleslead', path: PATH_CONTRACTOR.ourtask.salelead },
          { title: 'On Boarding', path: PATH_CONTRACTOR.ourtask.onboarding },
          { title: 'Review Data Updates', path: PATH_CONTRACTOR.ourtask.reviewdataupdates },
          { title: 'Review Uploads', path: PATH_CONTRACTOR.ourtask.reviewuploads },
          { title: 'Schedule Services', path: PATH_CONTRACTOR.ourtask.scheduleservice },
        ],
      },

    ],
  },
];

export default navConfig;

// routes
import { PATH_CUSTOMER } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  company: getIcon('ic_company'),
  contact: getIcon('ic_contact'),
  location: getIcon('ic_location'),
  credential: getIcon('ic_credential'),
  asset: getIcon('ic_asset'),
  feedback: getIcon('ic_feedback'),
  task: getIcon('ic_ourtask'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_CUSTOMER.general.dashboard, icon: ICONS.dashboard },
      {
        title: 'Our Customer',
        path: PATH_CUSTOMER.ourcustomer.root,
        icon: ICONS.user,
        children: [
          { title: 'Company Info', path: PATH_CUSTOMER.ourcustomer.companyinfo },
          { title: 'User Contact Info', path: PATH_CUSTOMER.ourcustomer.usercontactinfo },
          {
            title: 'Location Info', path: PATH_CUSTOMER.ourcustomer.locationinfo,
            // children: [
            //   { title: 'Location Form', },
            //   { title: 'Fire Dep Form', path: PATH_CUSTOMER.ourcustomer.fireinfo },
            //   { title: 'Key Logs Form', path: PATH_CUSTOMER.ourcustomer.keylogsinfo },
            //   { title: 'Elevator Form', path: PATH_CUSTOMER.ourcustomer.elevatorinfo },
            // ]
          },
          {
            title: 'Location Asset Inventory',
            children: [
              { title: 'Fire Alarm Asset', path: PATH_CUSTOMER.ourcustomer.firealarmasset },
              { title: 'Wet Sprinkler', path: PATH_CUSTOMER.ourcustomer.wetsprinkler },
              { title: 'Dry Sprinkler', path: PATH_CUSTOMER.ourcustomer.drysprinkler },
              { title: 'Preaction ', path: PATH_CUSTOMER.ourcustomer.preactionsprinkler },
              { title: 'Deluge ', path: PATH_CUSTOMER.ourcustomer.delugesprinkler },
              { title: 'Standpipe', path: PATH_CUSTOMER.ourcustomer.standpipe },
              { title: 'Fire Pump', path: PATH_CUSTOMER.ourcustomer.firepump },
              { title: 'Fire Hydrant', path: PATH_CUSTOMER.ourcustomer.firehydrant },
              { title: 'Kitchen Hood', path: PATH_CUSTOMER.ourcustomer.kitchenhood },
              { title: 'Dry Chemical', path: PATH_CUSTOMER.ourcustomer.drychemical },
              { title: 'Clean Agent', path: PATH_CUSTOMER.ourcustomer.cleanagent },
              { title: 'fire Escape', path: PATH_CUSTOMER.ourcustomer.fireescape },
              { title: 'Fire Extinguisher', path: PATH_CUSTOMER.ourcustomer.fireextinguisher },
              { title: 'Backflow', path: PATH_CUSTOMER.ourcustomer.backflow },
              { title: 'Other', path: PATH_CUSTOMER.ourcustomer.othersprinkler },


            ]
          },
          { title: 'Company Location Quantity', path: PATH_CUSTOMER.ourcustomer.companylocationquantity },
          { title: 'Services', path: PATH_CUSTOMER.ourcustomer.serviceentryform },
          { title: 'Jobs', path: PATH_CUSTOMER.ourcustomer.Jobentryform },
          { title: 'Deficiencies', path: PATH_CUSTOMER.ourcustomer.deficiencyentryform },
        ]
      },
      {
        title: 'Our Task',
        path: PATH_CUSTOMER.ourtask.root,
        icon: ICONS.task,
        children: [
          { title: 'Our Sales Lead', path: PATH_CUSTOMER.ourtask.saleslead },
          { title: 'On Boarding', path: PATH_CUSTOMER.ourtask.onboarding },
          { title: 'Review Data Update', path: PATH_CUSTOMER.ourtask.reviewdataupdate },
          { title: 'Review Upload', path: PATH_CUSTOMER.ourtask.reviewupload },
          { title: 'Schedule Service', path: PATH_CUSTOMER.ourtask.scheduleservice },
        ],
      },
    ],
  },
]
export default navConfig;

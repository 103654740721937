// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_SUPERADMIN = '/superadmin/panel';
const ROOTS_ADMIN_AUTH = '/admin';
const ROOTS_ADMIN = '/admin/panel';
const ROOTS_CUSTOMER_AUTH = '/customer';
const ROOTS_CUSTOMER = '/customer/panel';
const ROOTS_CONTRACTOR_AUTH = '/contractor';
const ROOTS_CONTRACTOR = '/contractor/panel';
const ROOTS_TECHNICIAN_AUTH = '/technician';
const ROOTS_TECHNICIAN = '/technician/panel';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_ADMIN_AUTH,
  login: path(ROOTS_ADMIN_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_ADMIN_AUTH = {
  root: ROOTS_ADMIN_AUTH,
  login: path(ROOTS_ADMIN_AUTH, '/login'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    company: path(ROOTS_ADMIN, '/company'),
    contact: path(ROOTS_ADMIN, '/contact'),
    location: path(ROOTS_ADMIN, '/location'),
    credential: path(ROOTS_ADMIN, '/credential'),
    assets: path(ROOTS_ADMIN, '/assets'),
    customerfeedback: path(ROOTS_ADMIN, '/customerfeedback'),
    customerapproval: path(ROOTS_ADMIN, '/customerapproval'),
    contractorapproval: path(ROOTS_ADMIN, '/contractorapproval'),
  },
};

export const PATH_CUSTOMER_AUTH = {
  root: ROOTS_CUSTOMER_AUTH,
  login: path(ROOTS_CUSTOMER_AUTH, '/login'),
  register: path(ROOTS_CUSTOMER_AUTH, '/register'),
  resetPassword: path(ROOTS_CUSTOMER_AUTH, '/reset-password'),
  newPassword: path(ROOTS_CUSTOMER_AUTH, '/new-password'),
  verify: path(ROOTS_CUSTOMER_AUTH, '/verify')
};


export const PATH_CUSTOMER = {
  root: ROOTS_CUSTOMER,
  general: {
    dashboard: path(ROOTS_CUSTOMER, '/dashboard'),
    location: path(ROOTS_CUSTOMER, '/location'),
  },
  ourcustomer: {
    root: path(ROOTS_CUSTOMER, '/ourcustomer'),
    companyinfo: path(ROOTS_CUSTOMER, '/ourcustomer/companyinfo'),
    usercontactinfo: path(ROOTS_CUSTOMER, '/ourcustomer/usercontactinfo'),
    locationinfo: path(ROOTS_CUSTOMER, '/ourcustomer/locationinfo'),
    elevatorinfo: path(ROOTS_CUSTOMER, '/ourcustomer/elevatorinfo'),
    fireinfo: path(ROOTS_CUSTOMER, '/ourcustomer/fireinfo'),
    keylogsinfo: path(ROOTS_CUSTOMER, '/ourcustomer/keylogsinfo'),
    firealarmasset: path(ROOTS_CUSTOMER, '/ourcustomer/firealarmasset'),
    wetsprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/wetsprinkler'),
    drysprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/drysprinkler'),
    preactionsprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/preactionsprinkler'),
    delugesprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/delugesprinkler'),
    standpipe: path(ROOTS_CUSTOMER, '/ourcustomer/standpipe'),
    firepump: path(ROOTS_CUSTOMER, '/ourcustomer/firepump'),
    firehydrant: path(ROOTS_CUSTOMER, '/ourcustomer/firehydrant'),
    kitchenhood: path(ROOTS_CUSTOMER, '/ourcustomer/kitchenhood'),
    drychemical: path(ROOTS_CUSTOMER, '/ourcustomer/drychemical'),
    cleanagent: path(ROOTS_CUSTOMER, '/ourcustomer/cleanagent'),
    fireescape: path(ROOTS_CUSTOMER, '/ourcustomer/fireescape'),
    fireextinguisher: path(ROOTS_CUSTOMER, '/ourcustomer/fireextinguisher'),
    backflow: path(ROOTS_CUSTOMER, '/ourcustomer/backflow'),
    othersprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/othersprinkler'),
    companylocationquantity: path(ROOTS_CUSTOMER, '/ourcustomer/companylocationquantity'),
    deficiencyentryform: path(ROOTS_CUSTOMER, '/ourcustomer/deficiencyentryform'),
    Jobentryform: path(ROOTS_CUSTOMER, '/ourcustomer/Jobentryform'),
    locationassetinventory: path(ROOTS_CUSTOMER, '/ourcustomer/locationassetinventory'),
    serviceentryform: path(ROOTS_CUSTOMER, '/ourcustomer/serviceentryform'),
  },
  ourtask: {
    root: path(ROOTS_CUSTOMER, '/ourtask'),
    saleslead: path(ROOTS_CUSTOMER, '/ourtask/saleslead'),
    onboarding: path(ROOTS_CUSTOMER, '/ourtask/onboarding'),
    reviewdataupdate: path(ROOTS_CUSTOMER, '/ourtask/reviewdataupdate'),
    reviewupload: path(ROOTS_CUSTOMER, '/ourtask/reviewupload'),
    scheduleservice: path(ROOTS_CUSTOMER, '/ourtask/scheduleservice'),

  },
};

export const PATH_CONTRACTOR_AUTH = {
  root: ROOTS_CONTRACTOR_AUTH,
  login: path(ROOTS_CONTRACTOR_AUTH, '/login'),
  register: path(ROOTS_CONTRACTOR_AUTH, '/register'), 
  resetPassword: path(ROOTS_CONTRACTOR_AUTH, '/reset-password'),
  newPassword: path(ROOTS_CONTRACTOR_AUTH, '/new-password'),
  verify: path(ROOTS_CONTRACTOR_AUTH, '/verify')
};

export const PATH_CONTRACTOR = {
  root: ROOTS_CONTRACTOR,
  general: {
    dashboard: path(ROOTS_CONTRACTOR, '/dashboard'),
    // company: path(ROOTS_CONTRACTOR, '/company'),
    // cuscompany: path(ROOTS_CONTRACTOR, '/cuscompany'),
    customer: path(ROOTS_CONTRACTOR, '/customer'),
    location: path(ROOTS_CONTRACTOR, '/location'),
    task: path(ROOTS_CONTRACTOR, '/task'),

  },
 
  ourcontractor: {
    root: path(ROOTS_CONTRACTOR, '/ourcontractor'),
    companyinfo: path(ROOTS_CONTRACTOR, '/ourcontractor/companyinfo'),
    usercontactinfo: path(ROOTS_CONTRACTOR, '/ourcontractor/usercontactinfo'),
  },

  ourtask: {
    root: path(ROOTS_CONTRACTOR, '/ourtask'),
    reviewdataupdates: path(ROOTS_CONTRACTOR, '/ourtask/reviewdataupdates'),
    salelead: path(ROOTS_CONTRACTOR, '/ourtask/salelead'),

    onboarding: path(ROOTS_CONTRACTOR, '/ourtask/onboarding'),

    scheduleservice: path(ROOTS_CONTRACTOR, '/ourtask/scheduleservice'),
    reviewuploads: path(ROOTS_CONTRACTOR, '/ourtask/reviewuploads'),
    
  },
 
};

export const PATH_TECHNICIAN_AUTH = {
  root: ROOTS_TECHNICIAN_AUTH,
  login: path(ROOTS_TECHNICIAN_AUTH, '/login'),
  resetPassword: path(ROOTS_TECHNICIAN_AUTH, '/reset-password'),
  newPassword: path(ROOTS_TECHNICIAN_AUTH, '/new-password'),
  verify: path(ROOTS_TECHNICIAN_AUTH, '/verify')
};

export const PATH_TECHNICIAN = {
  root: ROOTS_TECHNICIAN,
  general: {
    dashboard: path(ROOTS_TECHNICIAN, '/dashboard'),
    task: path(ROOTS_TECHNICIAN, '/task'),
    technicianinfo: path(ROOTS_TECHNICIAN, '/technicianinfo'),
    job: path(ROOTS_TECHNICIAN, '/jobs'),
    services: path(ROOTS_TECHNICIAN, '/services'),
    addtechnicianservice: path(ROOTS_TECHNICIAN, '/addtechnicianservice'),
    addjobentryform: path(ROOTS_TECHNICIAN, '/addjobentryform'),
    jobcoming: path(ROOTS_TECHNICIAN, '/jobscomingsoon'),
    feedback: path(ROOTS_TECHNICIAN, '/feedback'),
             
    


    analytics: path(ROOTS_TECHNICIAN, '/analytics'),
    banking: path(ROOTS_TECHNICIAN, '/banking'),
    booking: path(ROOTS_TECHNICIAN, '/booking')
  },
};

export const PATH_SUPERADMIN = {
  root: ROOTS_SUPERADMIN,
  general: {
    dashboard: path(ROOTS_SUPERADMIN, '/dashboard'),
    company: path(ROOTS_SUPERADMIN, '/company'),
    contact: path(ROOTS_SUPERADMIN, '/contact'),
    location: path(ROOTS_SUPERADMIN, '/location'),
    credential: path(ROOTS_SUPERADMIN, '/credential'),
    assets: path(ROOTS_SUPERADMIN, '/assets'),
    customerfeedback: path(ROOTS_SUPERADMIN, '/customerfeedback'),
    customerapproval: path(ROOTS_SUPERADMIN, '/customerapproval'),
    contractorapproval: path(ROOTS_SUPERADMIN, '/contractorapproval')
  },
  user: {
    root: path(ROOTS_SUPERADMIN, '/user'),
    profile: path(ROOTS_SUPERADMIN, '/user/profile'),
    cards: path(ROOTS_SUPERADMIN, '/user/cards'),
    list: path(ROOTS_SUPERADMIN, '/user/list'),
    newUser: path(ROOTS_SUPERADMIN, '/user/new'),
    editById: path(ROOTS_SUPERADMIN, `/user/reece-chung/edit`),
    account: path(ROOTS_SUPERADMIN, '/user/account')
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

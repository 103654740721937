// routes
import { PATH_ADMIN } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  company: getIcon('ic_company'),
  contact: getIcon('ic_contact'),
  location: getIcon('ic_location'),
  credential: getIcon('ic_credential'),
  asset: getIcon('ic_asset'),
  feedback: getIcon('ic_feedback'),
  approval: getIcon('ic_approval'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_ADMIN.general.dashboard, icon: ICONS.dashboard },
      { title: 'Company', path: PATH_ADMIN.general.company, icon: ICONS.company },
      { title: 'Contact', path: PATH_ADMIN.general.contact, icon: ICONS.contact },
      // { title: 'Location', path: PATH_ADMIN.general.location, icon: ICONS.location },
      { title: 'Credential', path: PATH_ADMIN.general.credential, icon: ICONS.credential },
      { title: 'Asset', path: PATH_ADMIN.general.assets, icon: ICONS.asset },
      { title: 'Customer Feed Back', path: PATH_ADMIN.general.customerfeedback, icon: ICONS.feedback },
      { title: 'Customer Approval', path: PATH_ADMIN.general.customerapproval, icon: ICONS.approval },
      { title: 'Contractor Approval', path: PATH_ADMIN.general.contractorapproval, icon: ICONS.approval },
    ],
  },
];

export default navConfig;
